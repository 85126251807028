<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">PDF / Imprimir</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="card" style="height: 1530px; min-width: 1000px">
        <div class="border-0 card-header">
          <button type="button" class="btn btn-sm btn-success float-left"
                  @click="back()">
            <span class="btn-label"><i class="fas fa-arrow-left"></i></span> VOLTAR
          </button>

          <div class="float-left invisible ml-3" v-if="isMobile()">.
          </div>

          <button type="button" class="btn btn-sm btn-primary float-left"
                  @click="print()" v-if="isApple()">
            <span class="btn-label"><i class="fas fa-print"></i></span> IMPRIMIR
          </button>
          <button type="button" class="btn btn-sm btn-info float-left"
                  @click="download()" v-if="isMobile()">
            <span class="btn-label"><i class="fas fa-file-download"></i></span> BAIXAR
          </button>
        </div>
        <object width='100%' height='100%' type="application/pdf"
                :data="'data:application/pdf;base64,'+encodeURI(pdfBase64)"></object>
      </div>
    </div>
  </div>
</template>
<script>
  import {hideLoading, showLoading} from '@/util/loading.utils'
  import {isApple, isMobile} from '@/util/core.utils'

  export default {
    data() {
      return {
        pageToBack: null,
        pdfBase64: null
      }
    },
    created() {
      if (this.$route.params.pdfBase64 == null) {
        this.$router.push("/dashboard")
      } else {
        this.pageToBack = this.$route.params.pageToBack
        this.pdfBase64 = this.$route.params.pdfBase64
      }
    },
    methods: {
      isApple,
      isMobile,
      download() {
        var a = document.createElement("a");
        a.href = "data:application/pdf;base64," + encodeURI(this.pdfBase64);
        a.download = "document.pdf";
        a.click();
      },
      print() {
        let embedEl = "<div class='show-pdf'>" +
          "<object width='1000px' height='1530px' type='application/pdf' data='data:application/pdf;base64," + encodeURI(this.pdfBase64) + "'></object>" +
          "</div>";

        showLoading()
        document.getElementsByClassName('wrapper-aux')[0].innerHTML = embedEl
        setTimeout(function () {
          window.print()
          setTimeout(function () {
            document.getElementsByClassName('wrapper-aux')[0].innerHTML = "";
            hideLoading()
          }, 1000, this)
        }, 3000, this)
      },
      back() {
        this.$router.push(this.pageToBack)
      }
    }
  }

</script>
<style>
  .no-border-card .card-footer {
    border-top: 0;
  }

  @media print {
    .wrapper,
    .is-full-page {
      display: none !important;
    }

    .wrapper-aux div.show-pdf {
      max-width: 100%;
      max-height: 1000px;
      overflow: hidden;
      page-break-after: always;
    }

    .wrapper-aux div.show-pdf object {
      zoom: 0.86; /* Old IE only */
      -moz-transform: scale(0.86);
      -webkit-transform: scale(0.86);
      transform: scale(0.86);
      transform-origin: top left;
    }
  }

  @media print and (hover: none) and (pointer: coarse) {
    .wrapper-aux div.show-pdf {
      max-height: 1200px;
    }
  }
</style>
